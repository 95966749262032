// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ar-landings-seguros-para-camiones-tsx": () => import("./../../../src/pages/ar/landings/seguros-para-camiones.tsx" /* webpackChunkName: "component---src-pages-ar-landings-seguros-para-camiones-tsx" */),
  "component---src-pages-ar-landings-seguros-para-comercio-tsx": () => import("./../../../src/pages/ar/landings/seguros-para-comercio.tsx" /* webpackChunkName: "component---src-pages-ar-landings-seguros-para-comercio-tsx" */),
  "component---src-pages-ar-plus-solicitar-acceso-tsx": () => import("./../../../src/pages/ar/plus/solicitar-acceso.tsx" /* webpackChunkName: "component---src-pages-ar-plus-solicitar-acceso-tsx" */),
  "component---src-pages-br-landings-cotar-seguro-de-carro-tsx": () => import("./../../../src/pages/br/landings/cotar-seguro-de-carro.tsx" /* webpackChunkName: "component---src-pages-br-landings-cotar-seguro-de-carro-tsx" */),
  "component---src-pages-br-partner-loggi-tsx": () => import("./../../../src/pages/br/partner/loggi.tsx" /* webpackChunkName: "component---src-pages-br-partner-loggi-tsx" */),
  "component---src-pages-br-partner-serasa-tsx": () => import("./../../../src/pages/br/partner/serasa.tsx" /* webpackChunkName: "component---src-pages-br-partner-serasa-tsx" */),
  "component---src-pages-br-plus-solicitar-acceso-tsx": () => import("./../../../src/pages/br/plus/solicitar-acceso.tsx" /* webpackChunkName: "component---src-pages-br-plus-solicitar-acceso-tsx" */),
  "component---src-pages-cl-landings-seguro-automotriz-soap-tsx": () => import("./../../../src/pages/cl/landings/seguro-automotriz/soap.tsx" /* webpackChunkName: "component---src-pages-cl-landings-seguro-automotriz-soap-tsx" */),
  "component---src-pages-cl-plus-solicitar-acceso-tsx": () => import("./../../../src/pages/cl/plus/solicitar-acceso.tsx" /* webpackChunkName: "component---src-pages-cl-plus-solicitar-acceso-tsx" */),
  "component---src-pages-co-plus-solicitar-acceso-tsx": () => import("./../../../src/pages/co/plus/solicitar-acceso.tsx" /* webpackChunkName: "component---src-pages-co-plus-solicitar-acceso-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mx-landings-cotizar-seguro-de-auto-tsx": () => import("./../../../src/pages/mx/landings/cotizar-seguro-de-auto.tsx" /* webpackChunkName: "component---src-pages-mx-landings-cotizar-seguro-de-auto-tsx" */),
  "component---src-pages-mx-seguros-auto-tsx": () => import("./../../../src/pages/mx/seguros/auto.tsx" /* webpackChunkName: "component---src-pages-mx-seguros-auto-tsx" */),
  "component---src-pages-uy-landings-cotizar-seguro-de-auto-tsx": () => import("./../../../src/pages/uy/landings/cotizar-seguro-de-auto.tsx" /* webpackChunkName: "component---src-pages-uy-landings-cotizar-seguro-de-auto-tsx" */),
  "component---src-pages-uy-seguros-auto-tsx": () => import("./../../../src/pages/uy/seguros/auto.tsx" /* webpackChunkName: "component---src-pages-uy-seguros-auto-tsx" */)
}

